.app {
  text-align: center;
}

.app-header {
  background-color: #282c34;
  min-height: 10vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.navigation {

}

.navigation__link {
  color: white;
  margin: 10px;
  text-decoration: none;
}

.navigation__link_active {
  text-decoration: overline;
}

.contact {

}

.contact__link {
  color: #282c34;
  text-decoration: none;
}

.app-body {
  background-color: #ffffff;
  min-height: 90vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: #282c34;
}

.mobile-app {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.mobile-app__title-block {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.mobile-app__image {
  border-radius: 10%;
  margin-right: 10px;
}

.mobile-app__store-buttons {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}